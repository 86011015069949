@tailwind base;

@tailwind components;
@tailwind utilities;
@layer base {
  img {
    @apply inline-block;
  }
  button {
    -webkit-tap-highlight-color: transparent;
  }

  svg {
    @apply transform-gpu;
  }
}

.hide-button-flash {
  -webkit-tap-highlight-color: transparent;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#gradient-canvas-0 {
  width: 100%;
  height: 100%;
  --gradient-color-1: #d1d5d8;
  --gradient-color-2: #d1d5d8;
  --gradient-color-3: #3399ff;
  --gradient-color-4: #3366cc;
}

#gradient-canvas-mobile-15 {
  width: 100%;
  height: 100%;
  --gradient-color-1: #ffcfd8;
  --gradient-color-2: #2f2440;
  --gradient-color-3: #c6b79b;
  --gradient-color-4: #ff2511;
}

#gradient-canvas-mobile-0 {
  width: 100%;
  height: 100%;
  --gradient-color-1: #ba0f30;
  --gradient-color-2: #2f2440;
  --gradient-color-3: #c6b79b;
  --gradient-color-4: #ff2511;
}

#gradient-canvas-mobile-1 {
  width: 100%;
  height: 100%;
  --gradient-color-1: #fbfdfe;
  --gradient-color-2: #00e6b3;
  --gradient-color-3: #1ecfbb;
  --gradient-color-4: #0e936c;
}
#gradient-canvas-mobile-4 {
  width: 100%;
  height: 100%;
  --gradient-color-1: #fbfdfe;
  --gradient-color-2: #00e6b3;
  --gradient-color-3: #1ecfbb;
  --gradient-color-4: #0e936c;
}

#gradient-canvas-mobile-2 {
  width: 100%;
  height: 100%;
  --gradient-color-1: #d1d5d8;
  --gradient-color-2: #d1d5d8;
  --gradient-color-3: #3399ff;
  --gradient-color-4: #3366cc;
}

#gradient-canvas-mobile-5 {
  width: 100%;
  height: 100%;
  --gradient-color-1: #ffa3b8;
  --gradient-color-2: #f7d6d0;
  --gradient-color-3: #fc3c80;
  --gradient-color-4: #821d30;
}

#gradient-canvas-mobile-3 {
  width: 100%;
  height: 100%;
  --gradient-color-1: #9fa4b2;
  --gradient-color-2: #ebe7e3;
  --gradient-color-3: #d4dcdd;
  --gradient-color-4: #ebbc52;
}

#gradient-canvas-1 {
  width: 100%;
  height: 100%;
  --gradient-color-1: #000000;
  --gradient-color-2: #d1d5d8;
  --gradient-color-3: #3399ff;
  --gradient-color-4: #3366cc;
}

#gradient-canvas-8 {
  width: 100%;
  height: 100%;
  --gradient-color-1: #4cd4b0;
  --gradient-color-2: #fffce6;
  --gradient-color-3: #f24d16;
  --gradient-color-4: #edd834;
}

#gradient-canvas-3 {
  width: 100%;
  height: 100%;
  --gradient-color-1: #5bc0eb;
  --gradient-color-2: #fde74c;
  --gradient-color-3: #9bc53d;
  --gradient-color-4: #e55934;
}

#gradient-canvas-4 {
  width: 100%;
  height: 100%;
  --gradient-color-1: #fe6860;
  --gradient-color-2: #fe8a71;
  --gradient-color-3: #d9bbae;
  --gradient-color-4: #f3c9bf;
}

#gradient-canvas-7 {
  width: 100%;
  height: 100%;
  --gradient-color-1: #043d5d;
  --gradient-color-2: #032e46;
  --gradient-color-3: #23b684;
  --gradient-color-4: #0f595e;
}

#gradient-canvas-6 {
  width: 100%;
  height: 100%;
  --gradient-color-1: #e0fbfc;
  --gradient-color-2: #c2dfe3;
  --gradient-color-3: #9db4c0;
  --gradient-color-4: #5c6b73;
}

#gradient-canvas-5 {
  width: 100%;
  height: 100%;
  --gradient-color-1: #ffa3b8;
  --gradient-color-2: #f7d6d0;
  --gradient-color-3: #fc3c80;
  --gradient-color-4: #821d30;
}

#gradient-canvas-2 {
  width: 100%;
  height: 100%;
  --gradient-color-1: #ba0f30;
  --gradient-color-2: #2f2440;
  --gradient-color-3: #c6b79b;
  --gradient-color-4: #ff2511;
}

#gradient-canvas-9 {
  width: 100%;
  height: 100%;
  --gradient-color-1: #fbfdfe;
  --gradient-color-2: #00e6b3;
  --gradient-color-3: #1ecfbb;
  --gradient-color-4: #0e936c;
}

#gradient-canvas-10 {
  width: 100%;
  height: 100%;
  --gradient-color-1: #9fa4b2;
  --gradient-color-2: #ebe7e3;
  --gradient-color-3: #d4dcdd;
  --gradient-color-4: #ebbc52;
}

#gradient-canvas-11 {
  width: 100%;
  height: 100%;
  --gradient-color-1: #000305;
  --gradient-color-2: #1e1b1c;
  --gradient-color-3: #48474d;
  --gradient-color-4: #7f8486;
}

#gradient-canvas-15 {
  width: 100%;
  height: 100%;
  --gradient-color-1: #fff;
  --gradient-color-2: #1e1b1c;
  --gradient-color-3: #ded7ff;
  --gradient-color-4: #000000;
}

.bg-accent-0 {
  background: linear-gradient(135deg, #d1d5d8 0%, #3399ff 50%, #3366cc 100%);
}

.bg-accent-1 {
  background: linear-gradient(135deg, #d1d5d8 0%, #3399ff 33%, #000000 100%);
}
.bg-accent-2 {
  background: linear-gradient(
    135deg,
    #c6b79b 0%,
    #ff2511 33%,
    #ba0f30 66%,
    #2f2440 100%
  );
}

.bg-accent-3 {
  background: linear-gradient(
    135deg,
    #9bc53d 0%,
    #5bc0eb 33%,
    #fde74c 66%,
    #e55934 100%
  );
}

.bg-accent-4 {
  background: linear-gradient(
    135deg,
    #fe6860 0%,
    #fe8a71 33%,
    #d9bbae 66%,
    #f3c9bf 100%
  );
}

.bg-accent-5 {
  background: linear-gradient(
    135deg,
    #ffa3b8 0%,
    #f7d6d0 33%,
    #fc3c80 66%,
    #821d30 100%
  );
}

.bg-accent-6 {
  background: linear-gradient(
    135deg,
    #e0fbfc 0%,
    #c2dfe3 33%,
    #9db4c0 66%,
    #5c6b73 100%
  );
}

.bg-accent-7 {
  background: linear-gradient(
    135deg,
    #043d5d 0%,
    #032e46 33%,
    #23b684 60%,
    #0f595e 100%
  );
}

.bg-accent-8 {
  background: linear-gradient(
    135deg,
    #4cd4b0 0%,
    #edd834 33%,
    #f24d16 66%,
    #fffce6 100%
  );
}

.bg-accent-9 {
  background: linear-gradient(
    135deg,
    #fbfdfe 0%,
    #00e6b3 33%,
    #1ecfbb 66%,
    #0e936c 100%
  );
}

.bg-accent-10 {
  background: linear-gradient(
    135deg,
    #9fa4b2 0%,
    #ebe7e3 33%,
    #ebbc52 66%,
    #d4dcdd 100%
  );
}

.bg-accent-11 {
  background: linear-gradient(
    135deg,
    #000305 0%,
    #48474d 50%,
    #1e1b1c 66%,
    #7f8486 100%
  );
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.pac-icon {
  display: none;
}

.pac-item {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.pac-item-query {
  font-size: 1rem; /* 16px */
  line-height: 1.15rem; /* 24px */
}

.pac-container {
  border: none;
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    /* Adjust the alpha value here */ 0 8px 10px -6px rgba(0, 0, 0, 0.1); /* Adjust the alpha value here */
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color),
    0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 0.375rem;
}
